
import { Component, Vue } from "vue-property-decorator";
import { Customer } from "@/config/Modules";
import ICustomer from "@/interfaces/ICustomer";

@Component({
  components: {},
})
export default class CustomerCarousel extends Vue {
  protected customers: Array<ICustomer> | null = null;

  protected activeCarouselIdx = 0;

  protected interval = 5000;
  protected maxItemsCount = 5;

  protected delayedMove(): void {
    if (this.customers && this.customers.length > 0) {
      const customersCount: number = this.customers.length;

      setInterval(() => {
        if (this.activeCarouselIdx >= customersCount) {
          this.activeCarouselIdx = 0;
        } else {
          this.activeCarouselIdx++;
        }
      }, this.interval);
    }
  }

  protected get itemsToShowCount(): number {
    return this.customers && this.customers.length < this.maxItemsCount
      ? this.customers.length
      : this.maxItemsCount;
  }

  public created(): void {
    this.$apiService.get(Customer.resource).then((response: any) => {
      console.debug("Customers ...", response.data);
      if (response?.data?.data) {
        this.customers = response.data.data;
        this.activeCarouselIdx = 0;
        this.delayedMove();
      }
    });
  }
}
