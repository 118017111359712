
import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import BaseLayout from "@/layout/BaseLayout.vue";
import HomeInfo from "@/components/HomeInfo.vue"; // @ is an alias to /src

@Component({
  components: {
    HomeInfo,
    BaseLayout,
    DefaultLayout,
  },
})
export default class Home extends Vue {}
