
import { Component, Vue } from "vue-property-decorator";
import CustomerCarousel from "@/components/modules/Home/CustomerCarousel.vue";

@Component({
  components: { CustomerCarousel },
})
export default class HomeInfo extends Vue {
  protected name = "HomeInfo";
}
